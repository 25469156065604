@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Box Model Hack */
* {
  box-sizing: border-box;
}



body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html {
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  color: #adadad;
}

/* header start */

.brand-logo-name-img {
  width: 110px;
  height: auto;
  margin-left: 2rem;
}

.main-nav li>a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.main-nav li>a:hover {
  color: #adadad;
}

.main-nav li {
  font-size: 2rem;
}

.main-nav ul {
  display: flex;
  margin-right: 2rem;
  padding: 10px;
  list-style: none;
  gap: 3.5rem;
}

.main-header {
  background-color: clear;
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  z-index: 100000;
  align-items: center;
}

.dropdown,
.hamburger {
  display: none;
}

.sticky {
  width: 100%;
  background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.8));

}

/* home component */

.main-section {
  background-image: url("./images/shapingFar.JPG");

  background-position: 0% 70%;
  background-repeat: no-repeat;
  background-size: cover;

  /* aligning the text in the paralax */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: -1;
}

.main-section div {
  text-align: center;
  min-width: 300px;
  max-width: 500px;
  margin: 4rem;
  z-index: 1000;
}

.message {
  color: white;
  font-weight: bold;
  font-size: 4.1rem;
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}


/* .brand-logo-name-img-main {
  height: 100px;
  display: none;
  width: auto;
} */


/* 2nd background */


.lochsa {
  background-image: url('./images/snyderLochsa.png');
  background-position: 0% 30%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lochsa h2 {
  font-size: 5rem;
  color: rgba(255, 255, 255);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.lochsa>div {
  display: flex;
  min-width: 300px;
  max-width: 500px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 4rem
}


/* footer */

footer {
  margin: 0;
  padding: .5rem 0;
  color: black;
  background-color: white;
  font-weight: bold;
}


footer a {
  color: black;
}

footer a:hover {
  color: #adadad;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footer-logos-ul {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 0;
  list-style: none;
  font-size: 1.5rem;
  margin: 5px;
  font-size: 2rem;
}

.copyright {
  text-align: center;
  font-weight: lighter;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;

}

.boldName{
  font-weight: bold;
}

/* contact page */
.contactPage {
  background-color: rgb(255, 255, 255);
  width: 100%;
  min-height: 70vh;
  margin: 0 auto;
  padding: 5rem 2rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 2rem;
}

.contactDiv h3 {
  margin-top: 0;
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.contactDiv ul {
  list-style: none;
  padding-left: 0;
}

.contactDiv li {
  margin-bottom: 2rem;
}

.contactDiv a {
  text-decoration: none;
  color: #000000;
}

.contactDiv a:hover {
  color: #adadad;
}

.contactDiv i {
  font-size: 2.5rem;
  padding-right: 15px;
}

/* formerly contactPage section */
.contact {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.contact-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}


/* form */


fieldset {
  padding: 0;
  border: none;
  display: flex;
  justify-content: space-between;
}

.contactPage fieldset div {
  width: 49%;
}

.buttonDiv {
  display: flex;
  justify-content: center;
}

.submit-button {
  display: block;
  align-self: center;
  width: 100%;
  padding: 1rem;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: .5rem;
  cursor: pointer;
  border: none;
  font-size: 2rem;
  font-weight: bold;
}

.submit-button:hover {
  color: white;
  background-color: rgb(100, 100, 100);

}

.form-group {
  margin-bottom: 2rem;
}

.form-control {
  font-size: 2rem;
  display: block;
  width: 100%;
  height: 3rem;
  padding: .75rem 1rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-textarea {
  min-height: 120px;
  width: 100%;
  resize: vertical;
  border-radius: 5px;
  font-size: 2rem;
  font-family: inherit;
}

.returnMessage {
  font-size: 3.5rem
}


/* Gallery */
/* <!-- https://www.youtube.com/watch?v=vrAftV1XcNc --> */

.gallery {
  color: #000000;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.galleryPhotos {
  width: 98%;
  background: white;
  margin: 50px auto;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow-x: auto;
  gap: 20px;
}

.galleryPhotos::-webkit-scrollbar{
  visibility: visible;
  height: 8px;
}

/* Track */
.galleryPhotos::-webkit-scrollbar-track {
  background: clear;
}

/* Handle */
.galleryPhotos::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}
.galleryPhotosScroll::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle on hover */
.galleryPhotos::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.photoDiv img {
  height: 400px;
  width: auto;
  left: 0;
  text-align: center;
  margin: 10px 10px;
  color: #000000;
  transition: 0.5s;
  display: block;
  border-radius: 5px;
  /* margin-bottom:10px ; */
}

.errorPage{
  background-image: url("./images/surfing1.jpeg");

  background-position: 0% 70%;
  background-repeat: no-repeat;
  background-size: cover;

  /* aligning the text in the paralax */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.errorPage h1 {
  font-size: 5rem;
  color: rgba(255, 255, 255);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

/* media queries */


@media screen and (max-width: 800px) {
  .footer-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
  }

  .footerOrder:nth-child(1) {
    order: 3;
  }
  .footerOrder:nth-child(2) {
    order: 2;
  }
  .footerOrder:nth-child(3) {
    order: 1;
  }
}

@media screen and (max-width: 660px) {
.form-control, .input-textarea {
  font-size: 1.7rem;
}

.main-nav ul{
  gap: 2rem;
}

.lochsa{
  background-position: 100% 30%;
}

}